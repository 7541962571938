// export class Config {
// 	public  _apiSite = "https://programme.worldwaterweek.org/";
// 	public  _apiSiteWordpress = "https://admin.worldwaterweek.org/wp-json/siwiWP/v1/";
// 	public  _apiEndpoint = "https://programme.worldwaterweek.org/API/";
// 	public  _appVersion = "Version 5.16.24 Build 20240516";
// 	public  _appName = "";
// 	public  _appTitle = "Siwi World Waterweek";
// }
export class Config {
	public  _apiSite = "https://siwiv2.websearchpro.net/";
	public  _apiSiteWordpress = "https://worldwaterweekadmin.websearchpro.net/wp-json/siwiWP/v1/";
	public  _apiEndpoint = "https://siwiv2.websearchpro.net/API/";
	public  _appVersion = "Version 6.9.24 Build 20240609";
	public  _appName = "";
	public  _appTitle = "Siwi World Waterweek";
}

